import { ProgressBar, Stack } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import useVideo from "../PreviewVideo/useVideo";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./styles.css";
import { APIClient } from "../../utils/services";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAudiosQuery } from "../../API";
import toast from "react-hot-toast";
import { useClearConfigs } from "../../store/hooks";
import mixpanel from "mixpanel-browser";
import React from "react";
import Hls from "hls.js";
import videojs from "video.js";

const TITLE = "Preview";
const TITLE_SHARE_MEDIAS = "Share your Video 🔥";
const HEADER =
  "While your video is generating you can create a new one. We'll send you an email when your videos are done processing";
const PREVIEW =
  "Starting transitions and preparing preview generation of your video. Soon the preview of your video will appear here";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

interface Response {
  result: {
    video_settings: {
      audio: { name: string };
      style: {
        dreambooth_id: string | null;
        theme_id: string;
        subject_matter: string[];
      };

      audio_reactive_config: {
        enable_vocal_shake: boolean;
        enable_zoom_pulse_for_drums: boolean;
      };
    };
  }[];
}

export default function PreviewPage() {
  const {
    id,
    url,
    step,
    isDone,
    isWorking,
    isLoading,
    ownerId,
    frameProgress,
  } = useVideo();
  const client = new APIClient();
  const { clear } = useClearConfigs();

  const [currentFrameData, setCurrentFrameData] = useState({
    frameIndex: null,
    maxFrames: null,
    frameUrl: "",
  });

  useEffect(() => {
    if (frameProgress) {
      const { saved_frame_index, saved_frame_httplink, max_frames } =
        frameProgress;
      setCurrentFrameData({
        frameIndex: saved_frame_index,
        maxFrames: max_frames,
        frameUrl: saved_frame_httplink,
      });
    }
  }, [frameProgress]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/app/dashboard");
  };

  const location = useLocation();

  const { videoInfo } = location.state || {};

  let title: any;

  const [urlm3u8, setUrlm3u8] = useState<string | undefined>(undefined);

  const checkUrlExistence = async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url, {
        method: "HEAD",
      });

      console.log(response);

      if (response.status === 403) {
        console.warn("URL is forbidden (403):", url);
        return false;
      }

      if (response.ok) {
        return true;
      }

      return false;
    } catch (error) {
      console.error("Error checking URL existence:", error);
      return false;
    }
  };

  const setValidUrlm3u8 = useCallback(async () => {
    if (videoInfo && videoInfo.urlm3u8) {
      const isValid = await checkUrlExistence(videoInfo.urlm3u8);
      if (isValid) {
        setUrlm3u8(videoInfo.urlm3u8);
      } else {
        setUrlm3u8(undefined);
      }
    }
  }, [videoInfo]);

  useEffect(() => {
    setValidUrlm3u8();
  }, [videoInfo, setValidUrlm3u8]);

  if (videoInfo && videoInfo?.name) {
    title = videoInfo?.name;
  }

  const handleVideoEnd = () => {
    setValidUrlm3u8();
  };

  const hasVideo = id || (videoInfo && videoInfo?.id);

  useEffect(() => {
    if (!hasVideo) {
      navigate("/app/library/projects");
    }
  }, [hasVideo]);

  const [showUpscaleToast, setUpscaleToast] = useState(false);
  const [prompts, setPrompts] = useState<string[]>([]);
  const [audioName, setAudioName] = useState<string>("");
  const [beatPulse, setBeatPulse] = useState<string>("");
  const [cameraShake, setCameraShake] = useState<string>("");
  const [dreamboothId, setDreamboothId] = useState<string | null>(null);
  const [themeId, setThemeId] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script: ${src}`));
        document.head.appendChild(script);
      });
    };

    // Load the video.js script
    loadScript("https://vjs.zencdn.net/8.16.1/video.min.js")
      .then(() => {
        console.log("Video.js script loaded successfully");
        // Initialize any video.js functionalities here if needed
      })
      .catch((error) => {
        console.error(error);
      });

    // Cleanup function to remove the script if needed
    return () => {
      const script = document.querySelector(
        `script[src="https://vjs.zencdn.net/8.16.1/video.min.js"]`
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [urlm3u8]);

  const [search] = useSearchParams();
  const query = search.get("query") || "";
  const fetchVideoMetadata = useCallback((response: Response) => {
    if (response && response.result && response.result.length > 0) {
      const result = response.result[0];
      const { audio, style, audio_reactive_config } = result.video_settings;

      setPrompts(style.subject_matter);
      setAudioName(audio.name);
      setDreamboothId(style.dreambooth_id ? "Yes" : "No");
      setThemeId(style.theme_id);
      setBeatPulse(
        audio_reactive_config.enable_zoom_pulse_for_drums == true ? "Yes" : "No"
      );
      setCameraShake(
        audio_reactive_config.enable_vocal_shake == true ? "Yes" : "No"
      );
    }
  }, []);

  useEffect(() => {
    if (ownerId && id) {
      client.get_video_metadata(ownerId, id, fetchVideoMetadata);
    }
  }, [ownerId, id, fetchVideoMetadata]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const download = async (url: string, name: string) => {
    setIsDownloading(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = name + ".mp4";
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const downloadMobileRestyle = async (url: string, name: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = name + ".mp4";
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const openFacebook = (url: string, title: string) => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(title)}`;
    window.open(facebookUrl, "_blank");
  };

  const shareToSocialMedias = (url: string, title: string) => {
    download(url, title);
  };

  const getOnClickHandler = (url: any, title: any, platform: any) => {
    if (isMobileDevice()) {
      return () => shareToSocialMedias(url, title);
    } else {
      switch (platform) {
        case "facebook":
          return () => openFacebook(url, title);
        case "instagram":
          return () => openFacebook(url, title);
        case "tiktok":
          return () => openFacebook(url, title);
        case "youtube":
          return () => openFacebook(url, title);
        default:
          return null;
      }
    }
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className="ps big-card rounded m-1 d-flex flex-grow-1 align-items-center justify-content-center h-100"
          style={{ backgroundColor: "#141718" }}
        >
          <div
            className="d-flex flex-column flex-grow-1 justify-content-center align-items-center p-2"
            style={{ backgroundColor: "#141718" }}
          >
            <div className="row align-items-start">
              <div className="col-auto" style={{ width: "300px" }}>
                <button
                  className="btn"
                  onClick={() => navigate("/app/library/projects")}
                >
                  <i
                    className="bi bi-arrow-left"
                    style={{ fontSize: "large" }}
                  ></i>
                </button>
              </div>
              <div className="text-center">
                {isMobileDevice() && videoInfo?.sharing ? (
                  <h4 className="mt-3 fw-bold">{TITLE_SHARE_MEDIAS}</h4>
                ) : (
                  <h1 className="mt-3 fw-bold">{TITLE}</h1>
                )}
              </div>
            </div>
            {!isDone && !videoInfo?.sharing && !videoInfo?.isRestyledVideo && (
              <p style={{ textAlign: "center" }}>{HEADER}</p>
            )}

            <div
              className="d-flex flex-column p-3 overflow-hidden"
              style={{
                height:
                  isMobileDevice() && videoInfo?.sharing ? "250px" : "100%",
                width:
                  isMobileDevice() && videoInfo?.sharing ? "250px" : "100%",
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {showUpscaleToast && (
                  <UpscaleToast onClose={() => setUpscaleToast(false)} />
                )}
              </div>
              {(isDone && (
                <div
                  className="d-flex justify-content-center my-auto overflow-auto"
                  style={{ height: "100%", width: "100%" }}
                >
                  <video
                    // key={id}
                    controls
                    autoPlay
                    muted
                    ref={videoRef}
                    className="my-auto"
                    style={{ maxWidth: "100%" }}
                  />
                  {/* <source src={url} type="video/mp4" /> */}
                  {/* </video> */}
                </div>
              )) ||
                (videoInfo && videoInfo?.ok && (
                  <div
                    className="d-flex justify-content-center my-auto overflow-auto"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <video
                      key={videoInfo?.id}
                      controls
                      className="my-auto"
                      style={{ maxWidth: "100%" }}
                    >
                      <source src={videoInfo?.url} type="video/mp4" />
                    </video>
                  </div>
                )) || (
                  <>
                    {!isDone && <div style={{ height: "100%" }} />}
                    <Loading isLoading={isLoading} />
                    {isWorking && (
                      <ProgressBar
                        striped
                        animated
                        variant="success"
                        max={16}
                        now={step}
                      />
                    )}
                    <Control />
                  </>
                )}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              {isMobileDevice() && videoInfo?.sharing && (
                <p style={{ textAlign: "center" }}>
                  Please click on the below button to confirm:
                </p>
              )}
              {isMobileDevice() && !videoInfo?.sharing && (
                <div
                  className="row"
                  style={{
                    marginBottom: "30px",
                    marginTop: "10px",
                    marginLeft: "4px",
                    fontSize: "x-small",
                  }}
                >
                  {prompts.map((prompt, index) => (
                    <React.Fragment key={index}>
                      {prompt}
                      {index !== prompts.length - 1 && ". "}
                    </React.Fragment>
                  ))}
                  .
                </div>
              )}
              {isMobileDevice() &&
                !videoInfo?.sharing &&
                !videoInfo?.isRestyledVideo && (
                  <div className="row" style={{ marginBottom: "30px" }}>
                    <div style={{ fontSize: "small" }}>
                      <i
                        className="bi bi-file-music-fill"
                        style={{
                          marginRight: "10px",
                          color: "#FF30C4",
                          fontSize: "18px",
                        }}
                      ></i>
                      <strong>Song: </strong> {audioName}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      <i
                        className="bi bi-person-fill"
                        style={{
                          marginRight: "10px",
                          color: "#FF30C4",
                          fontSize: "19px",
                        }}
                      ></i>
                      <strong>Avatar: </strong> {dreamboothId}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      <i
                        className="bi bi-brush-fill"
                        style={{
                          marginRight: "10px",
                          color: "#FF30C4",
                          fontSize: "18px",
                        }}
                      ></i>
                      <strong>Theme: </strong>
                      {themeId}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      <i
                        className="bi bi-camera2"
                        style={{
                          marginRight: "10px",
                          color: "#FF30C4",
                          fontSize: "18px",
                        }}
                      ></i>
                      <strong>Camera Shake: </strong>
                      {cameraShake}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      <i
                        className="bi bi-activity"
                        style={{
                          marginRight: "10px",
                          color: "#FF30C4",
                          fontSize: "18px",
                        }}
                      ></i>
                      <strong>Beat Pulse: </strong>
                      {beatPulse}
                    </div>
                  </div>
                )}

              {isMobileDevice() && videoInfo?.sharing && (
                <div
                  className="create-video-btn btn"
                  style={{ marginLeft: "80px" }}
                >
                  <button
                    onClick={() => shareToSocialMedias(url, title)}
                    style={{
                      border: "None",
                      backgroundColor: "transparent",
                      alignContent: "center",
                      marginRight: "5px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Share my video
                  </button>
                  <i
                    className="bi bi-share"
                    style={{
                      fontSize: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  ></i>{" "}
                </div>
              )}
              {isDownloading && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <strong>preparing downloading to share ... </strong>
                  <Spinner />
                </div>
              )}
            </div>
            {isMobileDevice() &&
              !videoInfo?.sharing &&
              !videoInfo?.isRestyledVideo && (
                <div
                  className="d-flex justify-content-evenly"
                  style={{
                    marginTop: "20px",
                    width: "300px",
                  }}
                >
                  <button
                    className="create-video-btn btn mb-2 mt-auto mb-3"
                    style={{ fontSize: "xx-small" }}
                    onClick={handleClick}
                  >
                    Create another
                  </button>
                  {!videoInfo?.isRestyledVideo && (
                    <button
                      className="btn btn-success mt-auto mb-3"
                      style={{ fontSize: "xx-small" }}
                      onClick={async () => {
                        const success = await upScalePreview(ownerId, id);
                        if (success) {
                          mixpanel.track("Upscale", {
                            ownerId: ownerId,
                            videoId: id,
                          });
                          toast.success("Generating Video Upscale...", {
                            duration: 7000,
                          });
                          setTimeout(() => {
                            navigate("/app/library/projects");
                            clear();
                          }, 7000);
                        }
                      }}
                      disabled={!isDone}
                    >
                      Upscale Resolution
                    </button>
                  )}

                  {videoInfo?.isRestyledVideo && (
                    <button
                      className="btn btn-success mt-auto mb-3"
                      style={{ fontSize: "xx-small" }}
                      onClick={async () => {
                        const success = await upScalePreviewRestyle(
                          ownerId,
                          videoInfo?.id
                        );
                        if (success) {
                          // mixpanel.track("Upscale", {
                          //   ownerId: ownerId,
                          //   videoId: id,
                          // });
                          toast.success("Generating Video Upscale...", {
                            duration: 7000,
                          });
                          setTimeout(() => {
                            navigate("/app/library/restyled");
                            clear();
                          }, 7000);
                        }
                      }}
                    >
                      Upscale Resolution
                    </button>
                  )}
                  {!isDone && (
                    <button
                      className="btn btn-danger mt-auto mb-3"
                      style={{ fontSize: "xx-small" }}
                      onClick={async () => {
                        const success = await pauseMedia(
                          ownerId,
                          videoInfo?.id,
                          videoInfo?.stateMachine
                        );
                        if (success) {
                          toast.loading(
                            "Your cancel execution is currently in progress...",
                            { duration: 7000 }
                          );
                          setTimeout(() => {
                            toast.dismiss();
                            toast.success(success, { duration: 3000 });
                            setTimeout(() => {
                              navigate("/app/library/projects");
                              clear();
                            }, 5000);
                          }, 3000);
                        } else {
                          toast.error("Unable to pause execution", {
                            duration: 7000,
                          });
                        }
                      }}
                    >
                      Cancel video
                    </button>
                  )}
                </div>
              )}
            {isMobileDevice() && videoInfo?.isRestyledVideo && (
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-success mt-auto mb-2"
                  style={{ fontSize: "xx-small", marginRight: "10px" }}
                  onClick={async () => {
                    const success = await upScalePreviewRestyle(
                      ownerId,
                      videoInfo?.id
                    );
                    if (success) {
                      // mixpanel.track("Upscale", {
                      //   ownerId: ownerId,
                      //   videoId: id,
                      // });
                      toast.success("Generating Video Upscale...", {
                        duration: 7000,
                      });
                      setTimeout(() => {
                        navigate("/app/library/restyled");
                        clear();
                      }, 7000);
                    }
                  }}
                >
                  Upscale Resolution
                </button>

                <button
                  className="create-video-btn btn mb-2 mt-auto"
                  style={{ fontSize: "xx-small" }}
                  onClick={() =>
                    downloadMobileRestyle(videoInfo?.url, videoInfo?.name)
                  }
                >
                  Download Video
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-3">
            <h1 className="mt-3 fw-bold">{TITLE}</h1>
            {!isDone &&
              !videoInfo?.isRestyledVideo &&
              urlm3u8 !== undefined && (
                <p style={{ textAlign: "center" }}>{HEADER}</p>
              )}
            {!isDone &&
              !videoInfo?.isRestyledVideo &&
              urlm3u8 === undefined && (
                <p style={{ textAlign: "center" }}>{PREVIEW}</p>
              )}
            <div
              className="d-flex flex-column p-3 overflow-hidden"
              style={{ height: "100%" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {showUpscaleToast && (
                  <UpscaleToast onClose={() => setUpscaleToast(false)} />
                )}
              </div>
              {(isDone && (
                <div
                  className="d-flex justify-content-center my-auto overflow-auto"
                  style={{ height: "100%", width: "100%" }}
                >
                  <video
                    key={id}
                    controls
                    className="my-auto"
                    style={{ maxWidth: "100%" }}
                  >
                    <source src={url} type="video/mp4" />
                  </video>
                </div>
              )) ||
                (!isDone && (
                  <div
                    className="d-flex justify-content-center my-auto overflow-auto"
                    style={{ height: "100%", width: "100%" }}
                  >
                    {/* Exibe o vídeo HLS ou MP4 conforme o tipo de URL */}
                    {urlm3u8 !== undefined ? (
                      <video
                        id="my-video"
                        className="video-js vjs-default-skin"
                        controls
                        autoPlay
                        muted
                        loop
                        onEnded={handleVideoEnd}
                        style={{ maxWidth: "100%" }}
                        data-setup="{}"
                      >
                        <source src={urlm3u8} type="application/x-mpegURL" />
                      </video>
                    ) : (
                      videoInfo &&
                      videoInfo?.ok && (
                        <div
                          className="d-flex justify-content-center my-auto overflow-auto"
                          style={{ height: "100%", width: "100%" }}
                        >
                          {/* Se o vídeo estiver ok, exibe também */}
                          <video
                            key={videoInfo?.id}
                            controls
                            className="my-auto"
                            style={{ maxWidth: "100%" }}
                          >
                            <source src={videoInfo?.url} type="video/mp4" />
                          </video>
                        </div>
                      )
                    )}
                    {urlm3u8 === undefined && !videoInfo?.isRestyledVideo && (
                      <Spinner />
                    )}
                  </div>
                )) || (
                  <>
                    {!isDone && <div style={{ height: "100%" }} />}
                    <Loading isLoading={isLoading} />
                    {isWorking && (
                      <ProgressBar
                        striped
                        animated
                        variant="success"
                        max={
                          currentFrameData.maxFrames === undefined
                            ? 16
                            : currentFrameData.maxFrames || 16
                        }
                        now={
                          currentFrameData.frameIndex === undefined
                            ? step
                            : currentFrameData.frameIndex || step
                        }
                      />
                    )}
                    <Control />
                  </>
                )}
            </div>
          </div>
          <div className="d-flex flex-column p-3 justify-content-between h-100">
            <div className="d-flex flex-column text-center">
              {/* SIDE CONTENT */}
            </div>
            <div className="d-flex flex-column align-items-center">
              {!isDone && urlm3u8 !== undefined && (
                <button
                  className="btn btn-danger mb-2"
                  onClick={async () => {
                    const success = await pauseMedia(
                      ownerId,
                      videoInfo?.id,
                      videoInfo?.stateMachine
                    );
                    if (success) {
                      toast.loading(
                        "Your cancel execution is currently in progress...",
                        { duration: 7000 }
                      );
                      setTimeout(() => {
                        toast.dismiss();
                        toast.success(success, { duration: 3000 });
                        setTimeout(() => {
                          navigate("/app/library/projects");
                          clear();
                        }, 5000);
                      }, 3000);
                    } else {
                      toast.error("Unable to pause execution", {
                        duration: 7000,
                      });
                    }
                  }}
                >
                  Cancel video generation
                </button>
              )}
              {!videoInfo?.isRestyledVideo && (
                <button
                  className="create-video-btn btn mb-2"
                  onClick={handleClick}
                >
                  Create another
                </button>
              )}
              {!videoInfo?.isRestyledVideo && (
                <button
                  className="btn btn-success mt-auto mb-4"
                  onClick={async () => {
                    const success = await upScalePreview(ownerId, id);
                    if (success) {
                      mixpanel.track("Upscale", {
                        ownerId: ownerId,
                        videoId: id,
                      });
                      toast.success("Generating Video Upscale...", {
                        duration: 7000,
                      });
                      setTimeout(() => {
                        navigate("/app/library/projects");
                        clear();
                      }, 7000);
                    }
                  }}
                  disabled={!isDone}
                >
                  Upscale Resolution
                </button>
              )}
              {videoInfo?.isRestyledVideo && (
                <button
                  className="btn btn-success mt-auto mb-4"
                  onClick={async () => {
                    const success = await upScalePreviewRestyle(
                      ownerId,
                      videoInfo?.id
                    );
                    if (success) {
                      // mixpanel.track("Upscale", {
                      //   ownerId: ownerId,
                      //   videoId: id,
                      // });
                      toast.success("Generating Video Upscale...", {
                        duration: 7000,
                      });
                      setTimeout(() => {
                        navigate("/app/library/restyled");
                        clear();
                      }, 7000);
                    }
                  }}
                >
                  Upscale Resolution
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Loading(props: any) {
  const { isLoading } = props;
  const className = "d-flex flex-align-items-center justify-content-center";
  const style = { height: "100%" };
  return isLoading && <div {...{ className, style }}>Loading...</div>;
}

async function upScalePreview(owner_id: string, video_asset_id: string) {
  const service = new APIClient();
  const call = await service.get_upscale_video(owner_id, video_asset_id);
  const hasVideo =
    call.data.result.length > 0 &&
    call.data.result[0].UPSCALE_status !== "ERROR";

  if (hasVideo) {
    toast.error(
      "Upscaling process already applied to this video. Check in your video library",
      { duration: 7000 }
    );
    return;
  }
  try {
    await Promise.resolve(
      service.generate_upscale_video(owner_id, video_asset_id)
    );
    return true;
  } catch (error) {
    return false;
  }
}

async function upScalePreviewRestyle(owner_id: string, video_asset_id: string) {
  const service = new APIClient();
  const call = await service.get_upscale_video(owner_id, video_asset_id);
  const hasVideo =
    call.data.result.length > 0 &&
    call.data.result[0].UPSCALE_status !== "ERROR";

  if (hasVideo) {
    toast.error(
      "Upscaling process already applied to this video. Check in your video library",
      { duration: 7000 }
    );
    return;
  }
  try {
    await Promise.resolve(
      service.generate_upscale_restyle(owner_id, video_asset_id)
    );
    return true;
  } catch (error) {
    return false;
  }
}

async function pauseMedia(
  owner_id: string,
  video_id: string,
  execution_arn: string
) {
  const service = new APIClient();
  const result = await service.pause_media(owner_id, video_id, execution_arn);

  return result;
}

function UpscaleToast(props: any) {
  const { showUpscaleToast, onClose } = props;

  return (
    <Toast show={showUpscaleToast} onClose={onClose}>
      <Toast.Header>
        <strong className="me-auto">Muze</strong>
      </Toast.Header>
      <Toast.Body>Upscaling applied and in processing!</Toast.Body>
    </Toast>
  );
}

function Control() {
  const {
    name,
    status,
    url,
    frameProgress,
    formatedTime,
    isWorking,
    isVideoError,
  } = useVideo();
  const onClick = () => download(url);
  const className = "bi bi-download fs-5 btn btn-dark p-0 m-0 px-1";

  const [currentFrameData, setCurrentFrameData] = useState({
    frameIndex: null,
    maxFrames: null,
    frameUrl: "",
  });

  useEffect(() => {
    if (frameProgress) {
      const { saved_frame_index, saved_frame_httplink, max_frames } =
        frameProgress;
      setCurrentFrameData({
        frameIndex: saved_frame_index,
        maxFrames: max_frames,
        frameUrl: saved_frame_httplink,
      });
    }
  }, [frameProgress]);

  const statusGranularVideo =
    currentFrameData.frameIndex !== undefined &&
    currentFrameData.maxFrames !== undefined
      ? `Image frame ${currentFrameData.frameIndex} of ${currentFrameData.maxFrames}`
      : status;

  const adjustedName = name;
  const adjustedStatus = status;
  const adjustedFormatedTime = formatedTime;
  const frameIndex = currentFrameData.frameIndex ?? 0;
  const maxFrames = currentFrameData.maxFrames ?? 16;

  const progressBarPercentage = (frameIndex / maxFrames) * 100;

  const adjustInfo =
    currentFrameData.frameIndex !== undefined &&
    currentFrameData.maxFrames !== undefined
      ? `${progressBarPercentage.toFixed(0)}%`
      : adjustedFormatedTime;

  return (
    <Stack className="align-items-stretch gap-2">
      <Stack direction="horizontal" className="gap-2">
        {!isWorking && !isVideoError && <i {...{ onClick, className }} />}
        {isWorking && <Spinner />}
        <div className="me-auto">{`${adjustedName} - ${
          isVideoError ? "Error." : statusGranularVideo
        }`}</div>
        <div className="ps">{`~ ${adjustInfo}`}</div>
      </Stack>
    </Stack>
  );
}

function Spinner() {
  const style = { width: "1rem", height: "1rem" };
  const className = "spinner-border";
  return <div role="status" {...{ style, className }} />;
}

const download = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = "video.mp4"; // Nome do arquivo de download
  a.click();
  window.URL.revokeObjectURL(blobUrl);
};
