import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import useAudioTrim from "./useAudioTrim";
import "./styles.css";
// @ts-ignore
import Mirt from "react-mirt";
import "react-mirt/dist/css/react-mirt.css";
import TrimPlayer from "../GenerateVideo/AudioPage/SelectStartAndStop/TrimPlayer";
import { Toast } from "react-bootstrap";

export default function AudioTrim() {
  const {
    sendFilesByLibrary,
    isLoading,
    isLoadingProgress,
    setShowToastLimitDurationAudio,
    showToastLimitDurationAudio,
  } = useAudioTrim();

  const location = useLocation();

  const navigationState = location.state || {};
  const { files: fileFromNavigation } = navigationState;

  function AdvancedToastAudioValidation(props: any) {
    const { showAdvancedToast } = props;

    return (
      <Toast show={showAdvancedToast}>
        <Toast.Header>
          <i
            className="bi bi-info-circle"
            style={{ fontSize: "2rem", marginRight: "10px" }}
          />
          <strong className="me-auto">Muze Art</strong>
        </Toast.Header>
        <Toast.Body style={{ fontSize: "1rem", marginRight: "9px" }}>
          Audio duration exceeds the 4 minutes limit. Click the back button to
          try another audio
        </Toast.Body>
      </Toast>
    );
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center border rounded flex-grow-1 m-3 gap-3"
      style={{ position: "relative" }}
    >
      {!showToastLimitDurationAudio && <Back />}
      {showToastLimitDurationAudio && <ReturnNewUpload />}
      {/* <Toaster /> */}
      <div>
        {" "}
        {showToastLimitDurationAudio && <AdvancedToastAudioValidation />}
      </div>
      {fileFromNavigation && fileFromNavigation.length > 1 ? (
        <h2
          className={`ps ${
            window.innerWidth < 1600 ? "small-font text-center" : ""
          }`}
        >
          You selected {fileFromNavigation.length} music(s), upload by clicking
          on "Upload Audios".
        </h2>
      ) : (
        !showToastLimitDurationAudio && <h1 className="ps">Cut MP3</h1>
      )}
      <div className="col-6">
        {fileFromNavigation &&
        fileFromNavigation.length === 1 &&
        fileFromNavigation[0].url ? (
          <TrimPlayer
            url={fileFromNavigation[0].url}
            active={isLoading}
            index={0}
          />
        ) : null}
      </div>

      <div className="d-flex flex-column gap-4">
        {!showToastLimitDurationAudio && (
          <button className="upload-audio" onClick={sendFilesByLibrary}>
            {fileFromNavigation.length === 1 ? "Upload Audio" : "Upload Audios"}
          </button>
        )}
        <div style={{ width: "100%" }}>{isLoadingProgress && <Spinner />}</div>
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      />
    </div>
  );
}

function Back() {
  const n = useNavigate();
  return (
    <i
      onClick={() => n("/app/assets-library/songs")}
      className={`bi bi-x btn btn-dark d-flex align-items-center justify-content-center`}
      style={{
        top: "3rem",
        width: "3rem",
        right: "3rem",
        height: "3rem",
        position: "absolute",
        borderRadius: "50%",
      }}
    />
  );
}

function ReturnNewUpload() {
  const n = useNavigate();
  return (
    <i
      onClick={() => n("/app/assets-library/songs")}
      className={`bi bi-arrow-left btn btn-dark d-flex align-items-center justify-content-center`}
      style={{
        top: "3rem",
        left: "3rem", // Adicionando a propriedade left para posicionar no canto superior esquerdo
        width: "3rem",
        height: "3rem",
        position: "absolute",
        borderRadius: "50%",
      }}
    />
  );
}
