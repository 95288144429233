import { useEffect, useRef, useState } from "react";
import { commonImageTypes, validFileType } from "../CreateAvatar/utils";
import { useGetImageAssetsQuery } from "../../API";
import { useStartingFrame } from "../../store/hooks";
import { APIClient } from "../../utils/services";
import { NextPrev } from "../AudioPage";
import { useAuth } from "../../AuthProvider";
import toast, { Toaster } from "react-hot-toast";

import "./styles.css";
import BoomerangSelector from "../GenerateVideo/ChooseStyle/BoomerangSelector";
import AspectRatioSelector from "../GenerateVideo/ChooseStyle/AspectRatioSelector";
import AnimationModeSelector from "../GenerateVideo/ChooseStyle/AnimationModeSelector";
import Name from "../GenerateVideo/ChooseStyle/Name";
import { useLocation, useNavigate } from "react-router-dom";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import axios from "axios";
import { useCustomEventListener } from "react-custom-events";
import mixpanel from "mixpanel-browser";
import { useDispatch } from "react-redux";
import { setSpotify } from "../../store/generateVideoReducer";

const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;
const { REACT_APP_MUZE_API_HOST, REACT_APP_IMAGES_CDN_URL } = process.env;
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

const TITLE = "Upload Image";
const SUBTITLE = "Upload your image";
const UPLOAD = "Upload";
const CFY_LYBRARY = "Choose from your library";
const MESSAGE = "Uploaded images will appears here.";

export default function ImagePage() {
  const [isUpload, setIsUpload] = useState(true);
  const [noImages, setNoImages] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { videoName } = useStartingFrame();
  const location = useLocation();

  const { copyInfo } = location.state || {};

  const isFromMusicVideo = location.state?.isMusicVideo || false;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 1600;

  const navigate = useNavigate();

  const createImage = () => {
    navigate("/app/create-an-image");
  };

  const SkipStepToMusic = () => {
    navigate("/app/create-video");
  };

  useEffect(() => {
    if (copyInfo) {
      setIsUpload(false);
    }
  }, [copyInfo]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps rounded m-2 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div
            className="col-12 d-flex flex-column p-1 gap-2"
            style={{ backgroundColor: "#343333" }}
          >
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
          </div>
          <div
            className={`d-flex flex-column flex-grow-1 align-items-center w-100 ${
              isMobileDevice() ? "p-1" : isSmallScreen ? "p-3" : "p-5"
            }`}
          >
            {!isUpload &&
              !noImages &&
              isSmallScreen &&
              typeof window.innerWidth === "number" && (
                <NextPrev hidePrev next={"../audio-select"} />
              )}
            {isSmallScreen && (
              <div>{isLoadingProgress && <SpinnerSmallScreen />}</div>
            )}
            <h1
              className={
                isSmallScreen
                  ? "p-3 d-flex text-center mb-4 fw-bold"
                  : "fw-bold my-5"
              }
            >
              {TITLE}
            </h1>

            <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
            <div className="d-flex justify-content-center col-12">
              {isUpload && (
                <UploadBtn
                  setIsUpload={setIsUpload}
                  setIsLoadingProgress={setIsLoadingProgress}
                />
              )}

              {!isUpload && <ChooseFromYourLibrary setNoImages={setNoImages} />}
            </div>
            <button className="create-video-btn btn mt-3" onClick={createImage}>
              I want to create an image
            </button>
            {!isUpload &&
              !noImages &&
              !isSmallScreen &&
              typeof window.innerWidth === "number" && (
                <NextPrev hidePrev next={"../audio-select"} />
              )}
          </div>
          {!isSmallScreen && <div>{isLoadingProgress && <Spinner />}</div>}
        </div>
      ) : (
        <div className="ps rounded m-3 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div className="border-bottom col-12 d-flex p-3 gap-5">
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
            <BoomerangSelector />
            {isFromMusicVideo && (
              <button className="btn-danger btn mt-3" onClick={SkipStepToMusic}>
                Skip this step
              </button>
            )}
          </div>
          <div
            className={`d-flex flex-column flex-grow-1 align-items-center w-100 ${
              isSmallScreen ? "p-3" : "p-5"
            }`}
          >
            {!isUpload &&
              !noImages &&
              isSmallScreen &&
              typeof window.innerWidth === "number" && (
                <NextPrev hidePrev next={"../audio-select"} />
              )}
            {isSmallScreen && (
              <div>{isLoadingProgress && <SpinnerSmallScreen />}</div>
            )}
            <h1
              className={
                isSmallScreen
                  ? "p-3 d-flex text-center mb-4 fw-bold"
                  : "fw-bold my-5"
              }
            >
              {TITLE}
            </h1>

            <Tabs setIsUpload={setIsUpload} isUpload={isUpload} />
            {isUpload && (
              <UploadBtn
                setIsUpload={setIsUpload}
                setIsLoadingProgress={setIsLoadingProgress}
              />
            )}
            <button className="create-video-btn btn mt-3" onClick={createImage}>
              I want to create an image
            </button>
            {!isUpload && <ChooseFromYourLibrary setNoImages={setNoImages} />}
            {!isUpload &&
              !noImages &&
              !isSmallScreen &&
              typeof window.innerWidth === "number" && (
                <NextPrev hidePrev next={"../audio-select"} />
              )}
          </div>

          {!isSmallScreen && <div>{isLoadingProgress && <Spinner />}</div>}
        </div>
      )}
    </>
  );
}

function ImageCard(props: any) {
  const { src, name } = props;
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();
  const dispatch = useDispatch();

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const selected = name === initialImageKey;

  const className = `d-flex flex-column align-items-center justify-content-center image-card ${
    selected && "image-selected"
  }`;

  const onClick = () => {
    setUserInitialImageKey(selected ? "" : name);
    dispatch(setSpotify(false));
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          {...{ className, onClick }}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "44%",
            position: "relative",
          }}
        >
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
          />
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
        </div>
      ) : (
        <div className={`${className} position-relative`} onClick={onClick}>
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "70%",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "large" }}
            ></i>
          </button>
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
            style={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
          />
        </div>
      )}
    </>
  );
}

function ChooseFromYourLibrary({ setNoImages }: { setNoImages: any }) {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/initial-image/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `${REACT_APP_IMAGES_CDN_URL}temp/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className="choose-from-library overflow-hidden">
      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard key={image.name} {...image} />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <div>
            <Paginator {...paginator} />
          </div>
        </>
      )}
      {isLoading && <Loading />}
      {isError && <Error />}
    </div>
  );
}

function SpinnerSmallScreen() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      />
    </div>
  );
}

function UploadBtn(props: any) {
  const { setIsUpload, setIsLoadingProgress } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
  const client = new APIClient();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoadingProgress(true);

    const droppedFiles = Array.from(e.dataTransfer.files);

    client.authenticate(undefined, undefined, API_KEY);

    if (droppedFiles.length > 0) {
      droppedFiles.forEach((file) => {
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          return;
        }

        client.upload_image_asset(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            mixpanel.track("Upload an image", {
              ownerId: ownerId,
              Image: file.name,
            });
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      });
    }
  };

  const handleChange = async (e: any) => {
    setIsLoadingProgress(true);
    const files = e.target.files;
    client.authenticate(undefined, undefined, API_KEY);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed:  ${file.name}`, {
            duration: 5000,
          });
          continue;
        }
        setIsLoadingProgress(true);
        client.upload_image_asset(ownerId, file, (i: any) => {
          if (i?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            mixpanel.track("Upload an image", {
              ownerId: ownerId,
              Image: file.name,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed:  ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "image/png, image/jpeg",
  };

  return (
    <div
      className="upload-btn"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...{ onClick }}
    >
      <input type="file" multiple {...inp} onChange={handleChange} />
      <i className="bi bi-upload" />
      <p>{SUBTITLE}</p>
    </div>
  );
}

function Tabs(props: any) {
  const { isUpload, setIsUpload } = props;

  return (
    <div className="d-flex flex-column overflow-auto gap-3">
      <div className="d-flex justify-content-center gap-5">
        <div
          onClick={() => setIsUpload(true)}
          style={{ borderBottom: isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {UPLOAD}
        </div>
        <div
          onClick={() => setIsUpload(false)}
          style={{ borderBottom: !isUpload ? "2px #FF30C4 solid" : "" }}
        >
          {CFY_LYBRARY}
        </div>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}

function useImageLibrary() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal, setItemsPerPage, itemsPerPage } = paginator;
  const page = actual;
  const perPage = itemsPerPage;
  const { data, isLoading, refetch, isError } = useGetImageAssetsQuery({
    ownerId,
    page,
    perPage,
  });
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const images = Array.isArray(data?.results)
    ? data.results.map((i: any, k: number, l: any[]) => ({
        img: `${imageBucketBaseURL}/${ownerId}/${i.Key}`,
        id: i.Key,
        isLast: k === l.length - 1,
      }))
    : [];

  return {
    refetch,
    images,
    isLoading,
    isError,
    setUserInitialImageKey,
    initialImageKey,
  };
}
