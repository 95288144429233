import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAvatarsQuery, useGetMetadataDefaultQuery } from "../../API";
import { useAuth } from "../../AuthProvider";
import { APIClient } from "../../utils/services";

import PopUp from "../SharedComponents/PopUp";
import { modelAvatar } from "../../utils/model-avatar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AvatarPreview from "../Library/AvatarPreview";
import AvatarPreviewImage from "./AvatarPreviewImage";

export default function AvatarSelector({
  selectedImage,
  setSelectedImage,
}: {
  selectedImage: string | null;
  setSelectedImage: (imageSrc: string | null) => void;
}) {
  const [avatars, setAvatars] = useState<React.ReactNode[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteAvatarId, setDeleteAvatarId] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false); // Adicionando uma variável de estado para controlar se a busca foi concluída
  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;
  const owner_default = "leeork+10@mymuze.art";
  const [sp, setSp] = useSearchParams();
  const query = sp.get("query");
  const { data, isSuccess, refetch, isLoading } = useGetAvatarsQuery({
    ownerId,
  });

  const {
    data: dataDefault,
    isSuccess: isSuccessDefault,
    isLoading: isLoadingDefault,
  } = useGetMetadataDefaultQuery({
    owner_default,
  });

  function Spinner() {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /mobile/.test(userAgent);
    };

    return (
      <div
        style={{ height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className="spinner-border"
          style={{
            width: isMobileDevice() ? "2rem" : "3rem",
            height: isMobileDevice() ? "2rem" : "3rem",
          }}
          role="status"
        />
      </div>
    );
  }

  const onDelete = (id: string) => {
    setDeleteAvatarId(id);
    setShowModal(true);
  };

  async function avatarPreview(owner_id: string, dreambooth_id: string) {
    const service = new APIClient();
    try {
      const result = (await service.dreambooth_get_preview_images(
        owner_id,
        dreambooth_id
      )) as any;
      return result;
    } catch (error) {
      return;
    }
  }

  async function avatarPreviewDefault(owner_id: string, dreambooth_id: string) {
    const service = new APIClient();
    try {
      const result = await service.dreambooth_get_preview_images_default(
        owner_id,
        dreambooth_id
      );
      return result;
    } catch (error) {
      console.error("Error fetching avatar preview:", error);
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (isSuccessDefault && isSuccess) {
        const defaultAvatars = dataDefault?.result?.records || [];
        const records = data?.result?.records || [];
        const updatedRecords: any = [];

        const stylesImages = await Promise.all(
          records.map((record: any) =>
            avatarPreview(ownerId, record.dreambooth_id)
          )
        );

        const defaultStylesImages = await Promise.all(
          defaultAvatars.map((avatar: any) =>
            avatarPreviewDefault(owner_default, avatar.dreambooth_id)
          )
        );

        defaultStylesImages.forEach((previewData, index) => {
          try {
            const thumbnailKeys = previewData.data?.result["thumbnail-keys"];

            const ownerIdEncoded = encodeURIComponent(owner_default);

            const firstImage =
              thumbnailKeys && thumbnailKeys.length > 0
                ? thumbnailKeys[9]?.images[0]?.http_link.replace(
                    owner_default,
                    ownerIdEncoded
                  )
                : "";

            updatedRecords.push({
              ...defaultAvatars[index],
              firstImage,
              isDefault: true,
            });
          } catch (error) {
            console.error(error);
          }
        });

        stylesImages.forEach((previewData, index) => {
          try {
            const thumbnailKeys = previewData.data?.result["thumbnail-keys"];
            const ownerIdEncoded = encodeURIComponent(ownerId);
            const firstImage =
              thumbnailKeys && thumbnailKeys.length > 0
                ? thumbnailKeys[9]?.images[0]?.http_link.replace(
                    ownerId,
                    ownerIdEncoded
                  )
                : "";

            updatedRecords.push({
              ...records[index],
              firstImage,
              isDefault: true,
            });
          } catch (error) {
            console.error(error);
            updatedRecords.push(records[index]);
          }
        });

        updatedRecords.sort(
          (a: any, b: any) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );

        const filteredAvatars = updatedRecords.filter(
          ({ name, date }: { name: string; date: string }) =>
            !query ||
            query === "" ||
            name.toLowerCase().includes(query.toLowerCase()) ||
            date.includes(query)
        );

        const formattedAvatars = filteredAvatars.map(
          (avatar: any, index: any) =>
            formatAvatars([avatar, index, filteredAvatars], setSp, onDelete)
        );

        setAvatars(
          formattedAvatars.map((avatar: any, index: any) => (
            <Card key={index} {...avatar} />
          ))
        );

        if (formattedAvatars) {
          setLoading(false);
          setSearchCompleted(true);
        }
      }
    };

    fetchData();
  }, [data, dataDefault, isLoadingDefault, isSuccessDefault, query]);

  const modal = {
    title: "Delete Avatar",
    text: "Are you sure you want to delete this Avatar?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      console.log("delete avatar: ", deleteAvatarId);
      client.avatar_delete(ownerId, deleteAvatarId, () => refetch());
      setDeleteAvatarId("");
      setShowModal(false);
    },
    onClose: () => {
      setDeleteAvatarId("");
      setShowModal(false);
    },
  };

  if (!isSuccess || !searchCompleted) {
    return (
      <div>
        <div className="d-flex flex-wrap align-items-center justify-content-center ps-3 gap-3">
          <Spinner />
        </div>
      </div>
    );
  }

  const showAvatar = !!sp.get("id");

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div>
          <div className="d-flex flex-wrap align-items-start justify-content-start ps-3 gap-3">
            {!loading && avatars.length === 0 && "0 avatar found."}{" "}
            <div className="d-flex flex-wrap justify-content-center">
              {!showAvatar && avatars}
            </div>
            {showAvatar && (
              <AvatarPreviewImage
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            )}
            <PopUp {...modal} />
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex m-3 p-3 flex-wrap align-items-start justify-content-start gap-2">
            {!loading && avatars.length === 0 && "0 avatar found."}{" "}
            {!showAvatar && avatars}
            {showAvatar && (
              <AvatarPreviewImage
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            )}
            <PopUp {...modal} />
          </div>
        </div>
      )}
    </>
  );
}

function formatAvatars(
  [i, k, l]: [any, number, []],
  setSp: Function,
  onDelete: Function
) {
  const status = i.dreambooth_status;
  const ok = status === "DONE";
  const statusAvatar =
    status !== "DONE" && status !== "ERROR" ? "IN PROGRESS" : status;

  const icon =
    status === "ERROR"
      ? "bi bi-exclamation-triangle-fill"
      : i.firstImage
      ? i.firstImage
      : "bi bi-clock-history";

  return {
    className: k === l.length - 1 ? "me-auto" : "",
    onClick: () => {
      if (i?.dreambooth_id) setSp({ id: i.dreambooth_id });
    },
    id: i.dreambooth_id,
    date: new Date(i.timestamp).toLocaleDateString(),
    icon: icon,
    name: i.name,
    status,
    onDelete,
    statusAvatar,
  };
}

function Card(props: any) {
  const {
    id,
    className,
    onClick,
    date,
    icon,
    name,
    status,
    statusAvatar,
    onDelete,
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const tooltip = (
    <Tooltip id={`tooltip-${icon}`}>
      Click to see thumbnails of the avatar in each style
    </Tooltip>
  );

  const isImage = icon.includes("https");

  return (
    <>
      {isMobileDevice() ? (
        <OverlayTrigger placement="top" overlay={tooltip} show={showTooltip}>
          <div
            className={`d-flex flex-column align-items-stretch justify-content-start btn border p-0 ${className}`}
            style={{
              width: "100%",
              marginBottom: "0.5rem",
              marginRight: "10px",
              maxWidth: "38%",
              position: "relative",
            }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={icon} alt="Avatar" style={{ maxWidth: "100%" }} />
            <i className={`bi bi-${icon}`} style={{ fontSize: "4rem" }} />
            <div className="mt-1">
              <p
                className="p-0 m-0 my-auto"
                style={{
                  textShadow: "#000000 1px 1px",
                  color: "white",
                  lineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  fontSize: "0.85rem",
                  fontWeight: "lighter",
                  height: "1rem",
                }}
              >
                {name}
              </p>
            </div>
          </div>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="top" overlay={tooltip} show={showTooltip}>
          <div
            className={`d-flex flex-column align-items-stretch justify-content-start btn border p-0 ${className}`}
            style={{
              height: "8rem",
              width: "6rem",
              position: "relative",
              marginBottom: "1.5rem",
            }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isImage ? (
              <img src={icon} alt="Avatar" style={{ maxWidth: "100%" }} />
            ) : (
              <i className={icon} style={{ fontSize: "5.2rem" }}></i>
            )}
            <div className="mt-1">
              <p
                className="p-0 m-0 my-auto"
                style={{
                  textShadow: "#000000 1px 1px",
                  color: "white",
                  lineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  fontSize: "0.85rem",
                  fontWeight: "lighter",
                  height: "1rem",
                }}
              >
                {name}
              </p>
            </div>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
}
