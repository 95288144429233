import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import { useGetAvatarByIdDefaultQuery, useGetAvatarByIdQuery } from "../../API";
import { Button, Modal } from "react-bootstrap";

export default function AvatarPreviewImage({
  selectedImage,
  setSelectedImage,
}: {
  selectedImage: string | null;
  setSelectedImage: (imageSrc: string | null) => void;
}) {
  const [previews, setPreviews] = useState<JSX.Element[]>([]);
  const n = useNavigate();
  const { currentUser } = useAuth();
  const [sp] = useSearchParams();
  const ownerId = `${currentUser?.email}`;
  const owner_default = "leeork+10@mymuze.art";
  const dreamboothId = sp.get("id") || "";
  const { data, isSuccess, isLoading } = useGetAvatarByIdQuery({
    ownerId,
    dreamboothId,
  });

  const navigate = useNavigate();

  const { data: dataDefault, isSuccess: isSuccessDefault } =
    useGetAvatarByIdDefaultQuery({
      owner_default,
      dreamboothId,
    });

  const handleCardClick = (imageSrc: any, e: React.MouseEvent<HTMLElement>) => {
    setSelectedImage(imageSrc);
    e.stopPropagation();
    console.log(selectedImage);
    backToAvatar();
  };

  useEffect(() => {
    if (isSuccess) {
      const query = sp.get("query");
      const thumbnails = data.result["thumbnail-keys"];
      const ownerIdEncoded = encodeURIComponent(ownerId);
      if (thumbnails && thumbnails.length > 0) {
        const modifiedPreviews = thumbnails
          .map((thumbnail: any) => {
            return {
              ...thumbnail,
              images: thumbnail.images.map((image: any) => ({
                ...image,
                http_link: image.http_link.replace(ownerId, ownerIdEncoded),
              })),
            };
          })
          .map(
            (thumbnail: any, index: number, array: any[]) =>
              formatCards(thumbnail, index, array, handleCardClick) // Pass handleCardClick here
          )
          .filter(
            ({ name, date }: { name: string; date: string }) =>
              !query ||
              query === "" ||
              name.toLowerCase().includes(query.toLowerCase())
          )
          .map((...v: any) => <Card key={v[1]} {...v[0]} />);
        setPreviews(modifiedPreviews);
      } else {
        setPreviews([]);
      }
    }

    if (isSuccessDefault) {
      const query = sp.get("query");
      const thumbnailsDefault = dataDefault?.result?.["thumbnail-keys"];
      const ownerIdEncodedDefault = encodeURIComponent(owner_default);
      if (thumbnailsDefault && thumbnailsDefault.length > 0) {
        const modifiedPreviewsDefault = thumbnailsDefault
          .map((thumbnail: any) => {
            return {
              ...thumbnail,
              images: thumbnail.images.map((image: any) => ({
                ...image,
                http_link: image.http_link.replace(
                  owner_default,
                  ownerIdEncodedDefault
                ),
              })),
            };
          })
          .map(
            (thumbnail: any, index: number, array: any[]) =>
              formatCards(thumbnail, index, array, handleCardClick) // Pass handleCardClick here as well
          )
          .filter(
            ({ name, date }: { name: string; date: string }) =>
              !query ||
              query === "" ||
              name.toLowerCase().includes(query.toLowerCase())
          )
          .map((...v: any) => <Card key={v[1]} {...v[0]} />);
        setPreviews((prevPreviews) => [
          ...prevPreviews,
          ...modifiedPreviewsDefault,
        ]);
      }
    }
  }, [data, dataDefault, sp, isSuccess, isSuccessDefault]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const showPreviews = previews.length > 0;

  function backToAvatar() {
    n("/app/create-an-image");
  }

  return (
    <>
      <Modal show={showPreviews}>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Avatar Styles</Modal.Title>
          <Button
            style={{ backgroundColor: "transparent", border: "none" }}
            className="ml-auto"
            onClick={backToAvatar}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {previews}
        </Modal.Body>
      </Modal>
    </>
  );
}

function formatCards(
  i: any,
  k: number,
  l: any[],
  handleCardClick: (imageSrc: any, e: React.MouseEvent<HTMLElement>) => void
) {
  return {
    name: i.Name,
    image: i.images[0].http_link,
    className: k === l.length - 1 ? "me-auto" : "",
    onClick: (e: React.MouseEvent<HTMLElement>) =>
      handleCardClick(i.images[0].http_link, e), // Passa o evento e o imageSrc
  };
}

function Card(props: any) {
  const { name, image, className, onClick } = props;
  return (
    <div
      className={`overflow-hidden rounded border btn btn-dark p-0 ${className}`}
      style={{ height: "10rem", width: "10rem" }}
      onClick={onClick}
    >
      <p
        className="text-center m-0 p-0"
        style={{
          textShadow: "#000000 1px 1px",
          color: "white",
        }}
      >
        {name}
      </p>
      <img
        className="m-0 p-0"
        width="100%"
        height="100%"
        src={image}
        alt="preview"
      />
    </div>
  );
}
