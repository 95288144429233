import { useNavigate } from "react-router-dom";
import "./styles.css";
import IMG_FC from "./music.png";
import IMG_SC from "./video-editing.png";
import IMG_TC from "./game.png";
import IMG_FFC from "./clapperboard.png";
import IMG_FFS from "./photo-gallery.png";
import IMG_CS from "./video-views.png";

const IMG_URL = `https://cdn0.iconfinder.com/data/icons/basic-user-interface-48/32/gallery_image_placeholder_pictures_photo_image-512.png`;

export default function DashboardPage() {
  const navigate = useNavigate();

  const handleClick = (to: string, isMusicVideo: boolean) => {
    if (isMusicVideo) {
      navigate(to, { state: { isMusicVideo } });
    } else {
      navigate(to);
    }
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <div
      className="ps big-card rounded m-1 p-1 d-flex flex-grow-1 flex-column align-items-center justify-content-center overflow-hidden"
      style={{ backgroundColor: "#141718" }}
    >
      <header className="p-1 d-flex text-center mb-1">
        <h1 className="fw-bold">{title}</h1>
      </header>
      <div className="d-flex flex-column overflow-auto">
        <div className="mx-4">
          {CARDS_MOBILE.map((card: any, index: number) => (
            <div
              key={index}
              className={`m-${window.innerWidth < 1600 ? 1 : 3} p-${
                window.innerWidth < 1600 ? 1 : 2
              } mb-3 rounded btn-dashboard d-flex`}
              onClick={() =>
                handleClick(card.to, card.title === "Create a Music Video")
              }
              style={{ cursor: "pointer" }}
            >
              <div className="img-box d-flex align-items-center flex-grow-1 overflow-hidden rounded">
                <img
                  className="img-dashboard-card"
                  alt="placeholder"
                  src={getCardImage(card.title)}
                />
                <h5
                  className="text-center flex-grow-1"
                  style={{ fontSize: "16px" }}
                >
                  {card.title}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="ps big-card rounded m-2 d-flex flex-grow-1 flex-column align-items-center justify-content-center overflow-hidden">
      <header className="p-2 d-flex text-center mb-1">
        <h1 className="fw-bold">{title}</h1>
      </header>
      <div className="d-flex flex-column overflow-auto">
        <div className="mx-4">
          <div className="d-flex justify-content-center">
            {CARDS.slice(0, 3).map((card: any) => {
              const isMusicVideo = card.title === "Create a Music Video";
              return (
                <Card
                  {...card}
                  key={card.title}
                  onClick={() => handleClick(card.to, isMusicVideo)}
                />
              );
            })}
          </div>
          <div className="d-flex  justify-content-center">
            {CARDS.slice(3, 6).map((card: any) => {
              const isMusicVideo = card.title === "Create a Music Video";
              return (
                <Card
                  {...card}
                  key={card.title}
                  onClick={() => handleClick(card.to, isMusicVideo)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function Card(props: any) {
  const n = useNavigate();
  const { title, description, to, video, onClick } = props;
  const src = IMG_URL;
  const showVideo = video && video !== "";
  return (
    <div
      className={`m-${window.innerWidth < 1600 ? 1 : 3} p-${
        window.innerWidth < 1600 ? 1 : 2
      } d-flex flex-column rounded btn-dashboard`}
      onClick={onClick}
    >
      <h3 className="text-center">{title}</h3>
      <p className="text-center text-muted" style={{ fontSize: "0.6rem" }}>
        {description}
      </p>
      <div className="img-box d-flex flex-grow-1 flex-column overflow-hidden rounded">
        {showVideo ? (
          <video
            autoPlay
            loop
            muted
            src={video}
            style={{ height: "100%", width: "auto", objectFit: "cover" }}
          />
        ) : (
          <img className="img-dashboard-card" alt="placeholder" {...{ src }} />
        )}
      </div>
    </div>
  );
}

function getCardImage(title: string) {
  switch (title) {
    case "Create a Music Video":
      return IMG_FC;
    case "Start with a template":
      return IMG_SC;
    case "Restyle a Video":
      return IMG_TC;
    case "Animate an Image":
      return IMG_FFC;
    case "Create an image":
      return IMG_FFS;
    case "Create an avatar":
      return IMG_CS;
    default:
      return IMG_URL;
  }
}

const title = "What would you like to create today?";

const CARDS = [
  {
    title: "Create a Music Video",
    description: "Upload a song and create a video edited to your beat ",
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Create+a+music+video.mp4`,
    to: "/app/image-to-video/image-select",
  },
  {
    title: "Start with a template",
    description: "Select a template to jumpstart your video creation process",
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Start+with+a+template.mp4`,
    to: "/app/template",
  },
  {
    title: "Restyle a Video",
    description: `Upload your video and restyle it with AI to create something new`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Restyle+a+video.mp4`,
    to: "/app/restyle-video",
  },
  {
    title: "Animate an Image",
    description: `Upload an image (like your album cover) and watch it come to life`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Animate+an+image.mp4`,
    to: "/app/image-to-video/image-select",
  },
  {
    title: "Create an image",
    description: "Generate an image or album cover",
    video:
      "https://muse-dashboard.s3.us-east-2.amazonaws.com/create-an-image.mp4",
    to: "/app/create-an-image",
  },
  {
    title: "Create an avatar",
    description:
      "Use your images or photos to create an avatar an add it to your videos",
    video:
      "https://muse-dashboard.s3.us-east-2.amazonaws.com/thumbnail+for+avatar+creation.mp4",
    to: "/app/create-avatar",
  },
];

const CARDS_MOBILE = [
  {
    title: "Create a Music Video",
    description: "Upload a song and create a video edited to your beat ",
    video: `src/components/DashboardPage/audio.png`,
    to: "/app/create-video",
  },
  {
    title: "Start with a template",
    description: "Select a template to jumpstart your video creation process",
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Start+with+a+template.mp4`,
    to: "/app/template",
  },
  {
    title: "Restyle a Video",
    description: `Upload your video and restyle it with AI to create something new`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Restyle+a+video.mp4`,
    to: "/app/restyle-video",
  },
  {
    title: "Animate an Image",
    description: `Upload an image (like your album cover) and watch it come to life`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Animate+an+image.mp4`,
    to: "/app/image-to-video",
  },
  {
    title: "Create an image",
    description: `Upload an image (like your album cover) and watch it come to life`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Animate+an+image.mp4`,
    to: "/app/create-an-image",
  },
  {
    title: "Create an avatar",
    description: `Upload an image (like your album cover) and watch it come to life`,
    video: `https://muse-dashboard.s3.us-east-2.amazonaws.com/Animate+an+image.mp4`,
    to: "/app/create-avatar",
  },
];
