import { useRef, useState } from "react";
// import { useLocation } from 'react-router-dom';
import { APIClient, upload_file } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import toast from "react-hot-toast";
import "./DropUpload.css";
import { emitCustomEvent } from "react-custom-events";
import { getInfo } from "react-mediainfo";
import { AxiosProgressEvent } from "axios";
import { ProgressBar } from "react-bootstrap";

const IMAGE_EXT = "bmp webp jpg jpeg png".split(" ");
const MUSIC_EXT = "wav ogg mp3".split(" ");
const API_KEY = process.env.REACT_APP_MUZE_API_KEY;

export default function FileDropInput() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [progress, setProgress] = useState(0);

  // let location = useLocation();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const client = new APIClient();
  client.authenticate(undefined, undefined, API_KEY);

  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [loading, setIsLoadingProgressRestyle] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  function Spinner() {
    return (
      <div
        style={{ height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className="spinner-border"
          style={{ width: "5rem", height: "5rem" }}
          role="status"
        />
      </div>
    );
  }

  const uploadImage = (file: File) => {
    setIsLoadingProgress(true);
    toast.success(`Sending image ${file.name} to image assets`);
    const okFunc = () => {
      toast.success(`Uploading image ${file.name} to image assets`);
      setIsLoadingProgress(false);
      emitCustomEvent("onImageUpload");
    };
    setIsLoadingProgress(true);
    client.upload_image_asset(ownerId, file, okFunc);
  };

  //vid2vid

  const uploadExternalVideo = async (file: File) => {
    if (/\s/.test(file.name)) {
      toast.error(
        "File name cannot contain spaces. Please use an underscore or hyphen instead.",
        {
          duration: 4000,
        }
      );
      setIsLoadingProgressRestyle(false);
      return;
    }

    setIsLoadingProgressRestyle(true);
    toast.success(`Sending original video ${file.name} to video assets`);

    let cleanedFileName = file.name;
    if (/[()\[\]{}]/.test(cleanedFileName)) {
      cleanedFileName = cleanedFileName.replace(/[()\[\]{}]/g, "");
    }

    const newFile = new File([file], cleanedFileName, { type: file.type });

    try {
      const videoInfo = await getInfo(newFile);
      let width = 0;
      let height = 0;

      if (videoInfo && videoInfo.media && videoInfo.media.track) {
        const videoTrack = videoInfo.media.track.find(
          (track: any) => track["@type"] === "Video"
        );
        if (videoTrack) {
          width = parseInt(videoTrack.Width || "0", 10);
          height = parseInt(videoTrack.Height || "0", 10);
        }
      }

      const onProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percent = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(percent);
        }
      };

      const okFunc = (response: any) => {
        toast.success(`Uploading video ${file.name} to video assets`);
        setIsLoadingProgressRestyle(false);
        emitCustomEvent("onVideoUpload");
      };

      const errFunc = (error: any) => {
        toast.error(`Failed to upload video ${file.name}: ${error.message}`);
        setIsLoadingProgressRestyle(false);
      };

      client.upload_external_video(
        ownerId,
        newFile,
        width,
        height,
        okFunc,
        errFunc,
        onProgress
      );
    } catch (error) {
      toast.error(`Failed to process video ${file.name}: ${error}`);
      setIsLoadingProgressRestyle(false);
    }
  };

  const uploadMusic = (file: File) => {
    const ingestAudio = (data: any) => {
      client.ingest_audio(ownerId, {
        delete_source: true,
        next_step_video: false,
        key: data.file_key,
        name: file.name,
      });
    };
    const okFunc = (data: any) => {
      if (data.status === "ok") {
        upload_file(data.signed_url, file, () => ingestAudio(data));
        toast.success(
          `Uploading file ${file.name} go to library to watch process`
        );
      }
    };
    client.get_audio_for_ingest_signed_url(ownerId, file.name, okFunc);
  };

  //defined these extensions?
  const VIDEO_EXT = ["mp4", "avi", "mov"];

  const filterMedia = (files: File[]) => {
    files.forEach((file: File) => {
      const arr = file.name.split(".");
      if (arr.length > 1) {
        const ext = arr[arr.length - 1].toLowerCase();
        if (IMAGE_EXT.includes(ext)) {
          console.log("upload image: ", file.name);
          uploadImage(file);
        } else if (VIDEO_EXT.includes(ext)) {
          console.log("upload video: ", file.name);
          uploadExternalVideo(file);
        } else if (MUSIC_EXT.includes(ext)) {
          console.log("Use upload audios tab to upload: ", file.name);
          toast.error("Use upload audios tab to upload: " + file.name, {
            duration: 10000,
          });
        } else {
          console.error("cannot upload, file type unknown: ", ext, file.name);
        }
      }
    });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    filterMedia(droppedFiles);
  };
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    filterMedia(selectedFiles);
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const c1 = "bi bi-arrow-bar-up fs-4 m-auto text-black";
  const t1 = "You can upload your videos here";
  const t2 = "You can upload images here";
  const s3 = { fontSize: "0.75rem", color: "white" };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return isMobileDevice() ? (
    <div
      className="file-drop-input"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        ref={fileInputRef}
      />
      <button
        className="btn btn-primary btn-floating"
        style={{
          backgroundColor: "#ff57d0",
          border: "none",
          borderRadius: "10px",
        }}
        onClick={openFileDialog}
      >
        <i className="bi bi-plus-circle-fill"></i>
      </button>
      <div style={{ width: "100%" }}>{isLoadingProgress && <Spinner />}</div>
      <div style={{ width: "100%" }}>
        {loading && (
          <ProgressBar
            now={progress}
            variant="success"
            style={{ backgroundColor: "#ff30c4" }}
            label={`${progress}%`}
          />
        )}
      </div>
    </div>
  ) : (
    <div
      className="file-drop-input"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        ref={fileInputRef}
      />
      <button onClick={openFileDialog} className="file-input-button">
        <div className="file-input-button-offset">
          <div className="upload-icon">
            <i id="up-media" className={c1}></i>
          </div>
          <h4 className="prevent-select text-white">Upload</h4>
          <p className="prevent-select" style={s3}>
            {t2}
          </p>
        </div>
      </button>
      <div style={{ width: "100%" }}>{isLoadingProgress && <Spinner />}</div>
      <div style={{ width: "100%" }}>
        {loading && (
          <ProgressBar
            now={progress}
            variant="success"
            style={{ backgroundColor: "#ff30c4" }}
            label={`${progress}%`}
          />
        )}
      </div>
    </div>
  );
}
