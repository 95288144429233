import { useLocation } from "react-router-dom";
import {
  useArtStyle,
  useAudioAsset,
  useStartingFrame,
} from "../../store/hooks";
import "./styles.css";

export default function Steps() {
  const l = useLocation();
  const showPaths = ["create-video", "image-to-video"];
  let s = MYSTEPS;

  const { initialImageKey } = useStartingFrame();
  const { selectedMusic } = useAudioAsset();
  const { themeId } = useArtStyle();
  const { subjectMatter } = useStartingFrame();

  const isPreview = l.pathname.split("/").includes("preview-video");

  const CREATE_VIDEO_STEPS = [
    {
      label: "Upload your song",
      completed: !!selectedMusic.asset_id || isPreview,
    },
    { label: "Choose an art style", completed: themeId !== "" || isPreview },
    { label: "Add your prompts", completed: subjectMatter !== "" || isPreview },
    { label: "Preview your video", completed: isPreview || isPreview },
  ];

  const IMAGE_VIDEO_STEPS = [
    {
      label: "Upload your image",
      completed: initialImageKey !== "" || isPreview,
    },
    {
      label: "Upload your song",
      completed: !!selectedMusic.asset_id || isPreview,
    },
    { label: "Choose an art style", completed: themeId !== "" || isPreview },
    { label: "Add your prompts", completed: subjectMatter !== "" || isPreview },
    { label: "Preview your video", completed: isPreview },
  ];

  const stepList = [CREATE_VIDEO_STEPS, IMAGE_VIDEO_STEPS];
  const shouldAppears = showPaths.some((v: string, i: number) => {
    s = stepList[i];
    // s = CREATE_VIDEO_STEPS;
    return l.pathname.split("/").includes(v);
  });
  if (!shouldAppears) return <></>;

  return (
    <div className="overflow-auto ps">
      <div className="d-flex flex-column">
        {s.map((...v: any) => (
          <Step key={v[1]} {...v[0]} last={v[2].length - 1 === v[1]} />
        ))}
      </div>
    </div>
  );
}

function Step(props: any) {
  const { label, completed, last, onClick } = props;
  return (
    <div
      className="d-flex align-items-center p-2 gap-3"
      onClick={() => onClick && onClick()}
    >
      <div
        className="d-flex justify-content-center step-dot"
        style={{
          border: completed ? "" : "1px solid #198754",
          backgroundColor: completed ? "#198754" : "",
        }}
      >
        {!last && <div className="step-line" />}
      </div>
      {`${label}`}
    </div>
  );
}

const MYSTEPS = [
  { label: "Step 1", completed: true },
  { label: "Step 2", completed: true },
  { label: "Step 3", completed: false },
  {
    label: "Step 4",
    completed: false,
    onClick: () => console.log("go to step"),
  },
];
