import { APIClient, DTrainParams } from "../../utils/services";

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export const imageTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

export const commonImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
];

export function validFileType(filename: File, fileTypes: string[]) {
  return fileTypes.includes(filename.type);
}

export type TrainParams = {
  ownerId: string;
  files: any;
  name: string;
  category: string;
};

export function trainingForAvatarCreation(
  params: TrainParams,
  okFunc?: Function,
  errFunc?: Function
) {
  const { ownerId, files, name, category } = params;
  const client = new APIClient();
  client.authenticate(undefined, undefined, "abc123");
  const train_params: DTrainParams = {
    name,
    train: {
      instance_class: category, // to reflect what the avatar is (man/woman/cat)
      instance_token: "MuzeDreamBooth",
      max_train_steps: 1200, //10-2000
      num_class_images: 200, //10-300
    },
    files,
  };
  client.dreambooth_train(ownerId, train_params, okFunc, errFunc);
}
