import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useReloadAudioDefaultQuery, useReloadAudioQuery } from "../../API";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import PopUp from "../SharedComponents/PopUp";
import { ProgressBar } from "react-bootstrap";
import Paginator from "../Paginator";
import usePaginator from "../Paginator/usePaginator";
import { useCustomEventListener } from "react-custom-events";

const styleCardName: React.CSSProperties = {
  textShadow: "#000000 1px 1px",
  color: "white",
  lineClamp: 2,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  fontSize: "0.85rem",
  fontWeight: "normal",
};

export default function Audios() {
  const [src, setSrc] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteAssetId, setDeleteAssetId] = useState("");
  const [musics, setMusics] = useState<any[]>([]);
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const [isLoadingAudios, setIsLoadingAudios] = useState(false);
  const [reload, setReload] = useState(false);

  const [search] = useSearchParams();
  const query = search.get("query") || "";

  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;

  const page = actual;
  const perPage = 10;

  const { data, refetch } = useReloadAudioQuery({
    ownerId,
    page,
    perPage,
    query,
  });

  const { data: dataDefault, refetch: refetchDefault } =
    useReloadAudioDefaultQuery({
      page,
      perPage,
    });

  const showMusics = musics.length > 0;

  const getAudios = async () => {
    setIsLoadingAudios(true);
    try {
      const result = await refetch();

      const defaultResult = await refetchDefault();

      const mainItems = result.data?.results?.items || [];
      const defaultItems = defaultResult.data?.results?.items || [];
      const allItems = [...mainItems, ...defaultItems];

      const sortedArray = allItems.sort((a, b) => {
        const dateA = new Date(a.last_update).getTime();
        const dateB = new Date(b.last_update).getTime();
        return dateB - dateA;
      });

      setTotal(result.data?.results?.total_items || 0);

      const sortedMusics = sortedArray.map((...v) =>
        formatMusic(setSrc, deleteAudio, ...v)
      );

      setMusics(sortedMusics);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAudios(false);
    }
  };

  useEffect(() => {
    setTimeout(() => getAudios(), 2000);
  }, []);

  useEffect(() => {
    setTotal(
      data != null && dataDefault != null ? data.results.total_items : 0
    );
    getAudios();
  }, [data, dataDefault, query, reload]);

  const deleteAudio = async (id: any) => {
    setDeleteAssetId(id);
    setShowModal(true);
  };

  const modal = {
    title: "Delete Audio",
    text: "Are you sure you want to delete this audio?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: async () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      await client.delete_audio_resource(ownerId, deleteAssetId, () =>
        refetch()
      );
      setDeleteAssetId("");
      setShowModal(false);
      setReload((prev) => !prev);
    },
    onClose: () => {
      setDeleteAssetId("");
      setShowModal(false);
    },
  };

  const handleUploadSuccess = () => {
    setReload((prev) => !prev);
  };

  const isPlaying = Object.keys(src).includes("src");

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center flex-grow-1 px-3 gap-3">
        <div className="w-75 text-center"></div>
        {isPlaying && <Player {...src} />}
        {isLoadingAudios && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
        {!isLoadingAudios && showMusics && musics.length === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            <span>0 audios found.</span>
          </div>
        )}

        {showMusics &&
          (isMobileDevice() ? (
            <div className="d-flex flex-wrap justify-content-center">
              {musics.map((music) => (
                <Card key={music.id} {...music} />
              ))}
            </div>
          ) : (
            <div className="d-flex flex-wrap gap-3">
              {musics.map((music) => (
                <Card key={music.id} {...music} />
              ))}
            </div>
          ))}

        <PopUp {...modal} />
      </div>

      {showMusics && musics.length > 0 && (
        <div className="paginator-container">
          <Paginator {...paginator} />
        </div>
      )}
    </div>
  );

  function Spinner() {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /mobile/.test(userAgent);
    };

    return (
      <div
        style={{ height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div
          className="spinner-border"
          style={{
            width: isMobileDevice() ? "2rem" : "3rem",
            height: isMobileDevice() ? "2rem" : "3rem",
          }}
          role="status"
        />
      </div>
    );
  }

  function mapStatusToProgress(status: any) {
    switch (status) {
      case "DONE":
        return 10;
      case "WHISPER_END":
        return 9;
      case "WHISPER_START":
        return 8;
      case "STEM_ANALYSIS_END":
        return 7;
      case "STEM_ANALYSIS_START":
        return 6;
      case "UPDATE":
        return 5;
      case "STEM_SEPARATION_END":
        return 4;
      case "STEM_SEPARATION_START":
        return 3;
      case "IMPORTED_TO_S3":
        return 2;
      default:
        return 5;
    }
  }

  function Player(props: any) {
    const { src, name, icon, date, status, close } = props;
    return (
      <div
        className={`d-flex flex-column align-items-stretch justify-content-center rounded border`}
        style={{ position: "relative", width: "25rem" }}
      >
        <div
          className="p-2"
          style={{ position: "absolute", float: "right", right: 1, top: 1 }}
        >
          <i className="bi bi-x btn btn-dark" onClick={() => close()} />
        </div>
        <div className="d-flex flex-column align-items-center p-3 gap-2">
          <p className="m-0 p-0 small">{date}</p>
          <i className={`bi bi-${icon}`} style={{ fontSize: "4rem" }} />
          <p className="text-center m-0 p-0" style={styleCardName}>
            {name}
          </p>
          <p style={{ fontSize: "0.65rem" }}>{status}</p>
        </div>
        <audio
          controls
          {...{ src }}
          className="rounded-bottom mt-auto"
          style={{ width: "100%" }}
        />
      </div>
    );
  }

  function Card(props: any) {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /mobile/.test(userAgent);
    };

    const {
      id,
      name,
      icon,
      date,
      status,
      onDelete,
      onClick,
      isLast,
      className,
      deleteAudio,
      errorTimeOut,
      statusTimeOut,
      isLoading,
      isError,
      isDone,
      progress,
    } = props;

    return isMobileDevice() ? (
      <div
        className={`d-flex flex-column align-items-stretch justify-content-start btn border p-0 ${className}`}
        style={{
          width: "100%",
          marginBottom: "0.5rem",
          marginRight: "10px",
          maxWidth: "38%",
        }}
        onClick={onClick}
      >
        <i
          className="bi bi-trash px-2 py-1"
          style={{
            position: "relative",
            left: "2rem",
            top: "0.1rem",
            cursor: "pointer",
            color: "#fff",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onDelete) onDelete(id);
          }}
          onMouseEnter={(e: any) => {
            e.target.style.color = "#FF30C4";
          }}
          onMouseLeave={(e: any) => {
            e.target.style.color = "#fff";
          }}
        />
        <i className={`bi bi-${icon}`} style={{ fontSize: "2rem" }} />
        <div className="mt-0">
          <p
            className="p-0 m-0 my-auto"
            style={{
              color: "white",
              lineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              fontSize: "0.5rem",
              fontWeight: "lighter",
              height: "2.5rem",
            }}
          >
            {name}
          </p>
        </div>
      </div>
    ) : (
      <div
        className={`rounded border text-center btn ${className} ${isLast}`}
        style={{ width: "10rem", position: "relative" }}
        onClick={onClick}
      >
        <i
          className="bi bi-trash btn btn-dark border"
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "2rem",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            deleteAudio(id);
          }}
        />
        <p className="m-0 p-0 small">{date}</p>
        <i className={`bi bi-${icon}`} style={{ fontSize: "4rem" }} />
        <p className="text-center m-0 p-0" style={styleCardName}>
          {name}
        </p>
        <p style={{ fontSize: "0.65rem" }}>
          {errorTimeOut ? statusTimeOut : status}
        </p>
        {!isDone && !isLoading && !isError && !errorTimeOut && (
          <ProgressBar
            striped
            animated
            variant="success"
            max={10}
            now={progress}
            label={errorTimeOut ? statusTimeOut : status}
            style={{ width: "100%" }}
          />
        )}
      </div>
    );
  }

  function formatMusic(setSrc: Function, deleteAudio: Function, ...a: any) {
    const [i, k, l] = a;

    if (!i || !i.asset_id || !i.name || !i.AUDIO_status || !i.timestamp) {
      return console.log("error to read the audios");
    }

    const ok = i.AUDIO_status === "DONE";

    const d = {
      id: i.asset_id,
      name: i.name.split(".mp3")[0],
      isDone: i.AUDIO_status === "DONE",
      progress: mapStatusToProgress(i.AUDIO_status),
      IsError: i.AUDIO_status === "ERROR-States.TaskFailed",
      status: i.AUDIO_status,
      statusTimeOut: "TIMEOUT ERROR",
      errorTimeOut: i.AUDIO_status === "ERROR-States.Timeout",
      date: new Date(i.timestamp).toLocaleDateString(),
      icon: ok
        ? "file-earmark-music"
        : i.AUDIO_status === "ERROR-States.Timeout"
        ? "exclamation-triangle"
        : "clock-history",
      deleteAudio,
    };

    const set = () =>
      setSrc({ ...d, src: i.http_link, close: () => setSrc({}) });

    return {
      ...d,
      className: ok ? "btn-dark" : "",
      isLast: k === l.length - 1 ? "me-auto" : "",
      onClick: ok ? set : () => {},
    };
  }

  function filterMusic(
    { name, date }: { name: string; date: string },
    query: string
  ) {
    return (
      query === "" ||
      name.toLowerCase().includes(query.toLowerCase()) ||
      date.includes(query)
    );
  }

  const MUSICS_FOR_TEST = Array(20).fill({
    asset_id: "audio-09870987098-09870987-9870987098-0987089",
    name: "music name",
    AUDIO_status: "DONE",
    timestamp: Date.now(),
  });
}
